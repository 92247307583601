<template>
  <div id="card-carousel">
    <q-btn
      v-if="$q.platform.is.desktop && items && items.length"
      class="scroll-button left text-primary"
      icon="fas fa-chevron-left"
      @click="scrollLeft"
    />

    <div class="card-row" @scroll="handleScroll">
      <q-card
        v-for="(card, i) in items"
        :id="`card-${i}`"
        :key="`${card.latitude},${card.longitude},${i}`"
      >
        <div class="card-name">
          {{ isCarClub ? card.description : card.name }}
        </div>
        <img v-if="icon === 'url'" :src="card.attributes.logo_url">
        <m-icons-type
          v-else-if="icon === 'fa'"
          :type="searchType"
          size="16px"
          rounded
        />
        <m-icons-type
          v-else-if="icon === 'type'"
          :type="card.type.toLowerCase()"
          size="16px"
          rounded
        />
        <m-travel-icon
          v-else
          color="white"
          :bg-color="getTravelContentHex(icon)"
          :type="icon"
          size="24px"
          circle
        />

        <!-- Type specific info -->
        <div class="search-type">
          <departure-info
            v-if="hasTimetable"
            :id="card.attributes.atcocode || card.attributes.atoc"
            :card="card"
          />
          <div v-else-if="searchType === 'rental'">
            {{ card.attributes.address.line_one | titleCase }}, {{ card.attributes.city | titleCase }}, {{ card.attributes.address.post_code }}<br>
            {{ card.attributes.telephone | phoneNumber }}
          </div>
          <div v-else-if="searchType === 'carclub'">
            <ul class="vehicle-list">
              <li
                v-for="vehicle in card.attributes.vehicles"
                :key="`${card.latitude},${card.longitude},${vehicle.description}`"
              >
                {{ vehicle.description }}
              </li>
            </ul>
          </div>
          <div v-else-if="searchType === 'e-scooter'">
            {{ $t('escooter.remaining_range', { range: kmToMiles(card.attributes.current_range_metres / 1000) }) }}
          </div>
          <span v-else-if="searchType === 'sociability'">
            {{ card.type }}
          </span>
          <span v-else>{{ $tc(`content_type.${searchType}`) }}</span>
        </div>

        <div v-if="searchType === 'parking'" class="search-type parking-info">
          <q-icon name="fa fa-info-circle" class="light-blue-5" />
          {{ $t(`parking_restrictions`) }}
        </div>

        <div class="button-holder">
          <q-btn
            v-if="card.type === 'website'"
            unelevated
            align="center"
            color="primary"
            size="sm"
            :label="$t('website')"
            class="text-white q-mt-lg q-mr-sm book-btn"
            @click="openURL(card.attributes.url)"
          />
          <m-cta-button
            v-else-if="bookRoute(card)"
            class="flex justify-center items-center book-btn"
            base-size="12px"
            @ctaClick="$router.push({
              name: bookRoute(card),
              params: {
                origin: {
                  label: paramsLabel(card),
                  value: paramsValue(card),
                  place_id: paramsValue(card)
                }
              }
            })"
          >
            <q-icon name="fas fa-ticket-alt" class="q-mr-sm" />
            {{ $t('book') }}
          </m-cta-button>
          <div v-if="card.sociability" class="flex justify-between items-center full-width">
            <q-avatar size="28px" rounded>
              <img :src="require(`assets/${getAssetPath('sociability')}`)" alt="">
            </q-avatar>
            <q-chip v-if="getTopSociabilityTag(card)" dense color="primary">
              {{ getTopSociabilityTag(card) }}
            </q-chip>
            <m-cta-button
              class="flex justify-between items-center direction-btn dense"
              ghost
              base-size="12px"
              @ctaClick="openURL(card.sociability.sociability_url)"
            >
              <span>{{ getTopSociabilityTag(card) ? $t('view_accessibility') : $t('view_sociability') }}</span>
              <q-icon name="fas fa-chevron-right" />
            </m-cta-button>
          </div>
          <m-cta-button v-else class="flex justify-between items-center direction-btn" ghost base-size="12px" @ctaClick="openURL(`https://www.google.com/maps/dir/${latLng.latitude+','+latLng.longitude}/${card.latitude},${card.longitude}/`)">
            <q-icon name="fal fa-directions" />
            <span>{{ distance(i) }}</span>
            <q-icon name="fas fa-chevron-right" />
          </m-cta-button>
        </div>
      </q-card>
      <loading-card v-if="loading" />
      <loading-card v-if="loading" />
      <loading-card v-if="loading" />
      <q-card v-if="showEmptyState" class="empty-state">
        <m-empty-state
          v-if="searchType==='ferry-soon'"
          padding-top="12px"
          icon="fas fa-ship"
          size="52px"
          :font-size="24"
          label="Booking Service available soon"
        />
        <m-empty-state
          v-else-if="searchType==='mango'"
          padding-top="12px"
          icon="fas fa-bus"
          size="52px"
          :font-size="24"
          label="Booking Service available soon"
        />
        <m-empty-state
          v-else
          name="fas fa-map-marker-question"
          padding-top="0"
          size="52px"
          :font-size="30"
          :label="$t('nothing_found_nearby')"
        />
      </q-card>
    </div>

    <q-btn
      v-if="$q.platform.is.desktop && items && items.length"
      class="scroll-button right text-primary"
      icon="fas fa-chevron-right"
      @click="scrollRight"
    />

    <q-btn
      class="close-button"
      icon="fas fa-times"
      @click="() => $store.dispatch('discovery/resetItems')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MEmptyState, MCtaButton, MTravelIcon, MIconsType } from 'components/'
import { openURL } from 'quasar'
import { hasPermission } from 'utils/authentication'
import { distance as getDistances } from 'api/locations.js'
import loadingCard from './loadingCard.vue'
import departureInfo from './departureInfo.vue'
import { toTitleCase, kmToMiles } from 'utils/utils'
import travelContents from 'mixins/travelContents'

export default {
  components: { loadingCard, MEmptyState, departureInfo, MCtaButton, MTravelIcon, MIconsType },
  filters: {
    titleCase (str) {
      const strArr = str.split(' ')
      return strArr.map(item => toTitleCase(item)).join(' ')
    },
    phoneNumber (str) {
      const strArr = str.split('-')
      return strArr.join(' ')
    },
    distance (val) {
      return kmToMiles(val)
    }
  },
  mixins: [ travelContents ],
  data () {
    return {
      routes: {
        rail: 'trains'
      },
      timer: null,
      distances: [],
      fetchingDistance: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'discovery/items',
      loading: 'discovery/loading',
      searchType: 'discovery/searchType',
      curLocation: 'discovery/location',
      selected: 'discovery/selected',
      latLng: 'geolocate/latLng',
      searchLocation: 'map/searchLocation'
    }),
    showEmptyState () {
      if (this.loading) return false
      if (this.items?.length > 0) return false
      return true
    },
    isCarClub () {
      return this.searchType === 'carclub'
    },
    icon () {
      switch (this.searchType) {
      case 'e_scooter':
      case 'bikehire':
      case 'bikeshare':
        return 'url'
      case 'gas_station':
      case 'ev_station':
      case 'atm':
      case 'bar':
      case 'cafe':
      case 'car_wash':
      case 'car_repair':
      case 'restaurant':
        return 'fa'
      case 'sociability':
        return 'type'
      default:
        return this.searchType
      }
    },
    hasTimetable () {
      return ['rail', 'bus'].includes(this.searchType)
    }
  },
  watch: {
    selected (val) {
      if (val) {
        const i = this.items.findIndex(item => {
          return item.latitude === val.lat && item.longitude === val.lng
        })

        const selected = this.$root.$el.querySelector(`#card-${i}`)
        if (selected) selected.scrollIntoView({ behavior: 'smooth' })
      }
    },
    items (val) {
      if (val) {
        // Reset distances so we don't think they're cached
        this.distances = []
        this.$nextTick(() => {
          // Once items are loaded in, handle scroll on card-row to force update
          this.fetchTimetable(0)
          this.calculateDistances(0)
        })
      }
    }
  },
  methods: {
    openURL,
    kmToMiles,
    getTopSociabilityTag (card) {
      if (!card.sociability || !card.sociability.top_tags[0]) return null
      const filteredTags = card.sociability.top_tags.filter(tag => tag.length <= 16)
      return filteredTags[0] || null
    },
    paramsValue (item) {
      let value
      switch (item.type) {
      case 'flight':
      case 'carclub':
      case 'bikehire':
      case 'bus':
      case 'tram':
        value = `${item.latitude},${item.longitude}`
        break
      case 'rental':
        value = item.attributes.branch_code
        break
      default:
        value = item.description
      }
      return value
    },
    paramsLabel (item) {
      let label

      switch (item.type) {
      case 'carclub':
        label = item.description
        break
      case 'rental':
        label = `${item.name} - ${item.description}`
        break
      default:
        label = item.name
        break
      }

      return label
    },
    bookRoute ({ type, attributes }) {
      switch (type) {
      case 'rental':
        if (hasPermission('search.rentals')) return 'ondemand-car-hire'
        else return false
      case 'rail':
        if (hasPermission('search.trains')) return 'ondemand-trains'
        else return false
      case 'bus':
        if (hasPermission('search.bus')) return 'ondemand-bus'
        else return false
      case 'tram':
        if (hasPermission('search.tram')) return 'ondemand-tram'
        else return false
      case 'flight':
        if (hasPermission('search.flights')) return 'ondemand-flights'
        else return false
      case 'carclub':
        if (hasPermission('search.carclub')) return 'ondemand-carclub'
        else return false
      case 'bikehire':
        if (hasPermission('search.bikehire')) return 'ondemand-bikehire'
        else return false
      case 'ferry':
        if (hasPermission('search.ferry')) return 'ondemand-ferry'
        else return false
      case 'bikeshare':
        if (hasPermission('search.bikeshare')) return 'ondemand-bikeshare'
        else return false
      default:
        return null
      }
    },
    distance (cardIndex) {
      const distance = this.distances[cardIndex]
      if (!distance) return '?'
      const distanceInMetres = distance.distance_in_metres
      // convert metres to miles
      const distanceInMiles = distanceInMetres / 1609.344
      if (distance.mode === 'walking') {
        const travelTime = Math.round(distance.duration_in_seconds / 60)
        return `${this.$t('num.miles', { num: distanceInMiles.toFixed(1) })} / ${this.$t('minute_walk', { min: travelTime })}`
      }
      return this.$t('num.miles', { num: distanceInMiles.toFixed(1) })
    },
    async calculateDistances (startIndex = 0) {
      const location = this.searchLocation?.latitude
        ? this.searchLocation
        : this.latLng?.latitude ? this.latLng : null
      // If we don't have a location, don't perform the distance search
      if (!location) return
      this.fetchingDistance = true
      const origin = `${location.latitude},${location.longitude}`
      const destinations = this.items
        .filter((item, index) => {
          // If we've already got a distance for this card, don't check again
          return index >= startIndex && index < (startIndex + 10) && !this.distances[index]
        })
        .map(item => {
          return `${item.latitude},${item.longitude}`
        })

      if (destinations.length) {
        const res = await getDistances(origin, destinations)

        this.distances = res.data.destinations
      }
      this.fetchingDistance = false
    },
    handleScroll (e) {
      if (this.loading) return
      if (this.$q.platform.is.desktop) return
      // Set & reset timeout so we only reposition after user stops scrolling
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // Spread HTMLCollection of cards into an Array
        const children = [...e.target.children]

        // Filter finds the first card with a positive left margin
        const [ child ] = children.filter(child => {
          const { left } = child.getBoundingClientRect()
          return left > 0
        })

        if (child) {
          const i = Number(child.id.split('-')[1])
          const { latitude, longitude } = this.items[i]
          this.$store.dispatch('discovery/setSelected', {
            ...this.items[i],
            id: child.id,
            lat: latitude,
            lng: longitude
          })

          this.fetchTimetable(i)
          this.calculateDistances(i)
        }
      }, 150)
    },
    scrollRight () {
      const id = this.selected?.id || 'card-0'
      let i = Number(id.split('-')[1])
      if (i <= this.items.length - 2) {
        const { latitude, longitude } = this.items[++i]
        this.$store.dispatch('discovery/setSelected', {
          id: `card-${i}`,
          lat: latitude,
          lng: longitude
        })
        this.fetchTimetable(i)
      }
    },
    scrollLeft () {
      const id = this.selected?.id || 'card-0'
      let i = Number(id.split('-')[1])
      if (i >= 1) {
        const { latitude, longitude } = this.items[--i]
        this.$store.dispatch('discovery/setSelected', {
          id: `card-${i}`,
          lat: latitude,
          lng: longitude
        })
        this.fetchTimetable(i)
      }
    },
    fetchTimetable (i) {
      if (!this.hasTimetable) return
      this.$store.dispatch('discovery/setTimetable', { ...this.items[i], limit: 3 })

      if (this.items.length > 1) {
        if (i > 0) {
          this.$store.dispatch('discovery/setTimetable', { ...this.items[i - 1], limit: 3 })
        }
        if (i < this.items.length - 1) {
          this.$store.dispatch('discovery/setTimetable', { ...this.items[i + 1], limit: 3 })
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.vehicle-list
  list-style-type none
  margin 0
  padding 0

#card-carousel
  min-height 140px
  padding 8px 0
  display flex
  position absolute
  bottom -60px
  right 0
  left 0
  z-index 2
  margin-bottom 11vh

#card-carousel
  @media (min-width 768px)
    width 84%
    left 8%

.card-row
  width 100%
  display flex
  padding 2px 0
  overflow-x scroll
  scroll-snap-type x mandatory
  -ms-overflow-style none
  scrollbar-width none
  &::-webkit-scrollbar
    display none

.q-card
  scroll-snap-align center
  background white
  height 100%
  min-width 300px
  max-width 300px
  margin 0 4px
  padding 5px 10px
  border-radius 15px
  display grid
  grid-template-columns auto 24px
  align-items start
  grid-template-areas "card-name type-icon" \ "search-type search-type" \ "buttons buttons"
  img
    grid-area 1 / 1 / 3 / 3
    width 64px
    justify-self end
  @media (min-width 768px)
    min-width 60%

.empty-state
  display flex
  align-items flex-start
  justify-content center
  width 100vw

.card-name
  color var(--q-color-primary)
  font-weight 500
  grid-area card-name
  font-size 95%

.search-type
  font-size 85%
  grid-area search-type

.button-holder
  grid-area buttons
  width 100%
  display flex
  justify-content space-between
  .m-cta-button
    margin-inline 0
  .book-btn
    flex-basis 95px
    flex-grow 0
  .direction-btn
    flex-basis fit-content
    span
      margin 0 0.7em
  .direction-btn.dense
    margin 0
    padding 0
    span
      margin 0 0.125em

.scroll-button
  background rgba(255, 255, 255, 0.6)
  box-shadow none
  margin 2px 0
  width 35px
  &.left
    border-top-left-radius 15px
    border-bottom-left-radius 15px
  &.right
    border-bottom-right-radius 15px
    border-top-right-radius 15px

.parking-info
  position relative
  top 21px
  font-size: 70%

.close-button
  margin-right -38px
  margin-left 8px
  margin-top 4px
  height 30px
  width 30px
  border-radius 50%
  display flex
  align-items center
  justify-content center
  background-color white
  color var(--q-color-primary)
  opacity 0.9
</style>
