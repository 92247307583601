<template>
  <div>
    <div v-if="departures === 'error'" class="no-departures">
      <q-icon name="fas fa-wifi-slash" color="red" />
      {{ $t('train.timetable.not_available') }}
    </div>
    <div v-else-if="!departures">
      <component :is="spinnerName" size="16px" />
    </div>
    <div v-else>
      <m-timetable-row
        v-for="d in departures"
        :key="d.service_id"
        :item="d"
        @click.native="expandTimetable"
      />
    </div>

    <q-dialog
      v-model="showFullTimetable"
      class="full-timetable"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <m-mobility-nearby-details
        :id="id"
        :station="card"
        @close-dialog="showFullTimetable = false"
      />
    </q-dialog>
  </div>
</template>

<script>
import { MTimetableRow, MSpinnerGeo, MMobilityNearbyDetails, MSpinnerArrow, MSpinnerKinto } from 'components/'

export default {
  components: { MTimetableRow, MSpinnerGeo, MMobilityNearbyDetails, MSpinnerArrow, MSpinnerKinto },
  props: {
    id: String,
    card: Object
  },
  data () {
    return {
      showFullTimetable: false
    }
  },
  computed: {
    departures () {
      const departures = this.$store.getters['discovery/departures'][this.id]
      if (!departures) return null
      else if (departures.length === 0) return 'error'
      return departures.slice(0, 3)
    },
    spinnerName () {
      const spinnerType = this.$store.getters.partner.spinnerType
      return `m-spinner-${spinnerType}`
    }
  },
  methods: {
    expandTimetable () {
      this.showFullTimetable = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-icon
  transform rotate(90deg)

.no-departures
  display grid
  grid-template-columns 30px auto
  align-items: center
</style>
